import _ from 'lodash';
import type { SeoTagsPayload } from '@wix/native-components-infra/dist/src/types/types';
import type { ControllerParams } from '@wix/yoshi-flow-editor';
import {
  RejectType,
  type UIRouter,
  type Transition,
  type HookMatchCriteria,
} from '@wix/tpa-router';

import { monitor } from './error-monitor';

/**
 * SEO helper tool
 * will call `resolver` with all resolvables for current route
 * so you can use them to generate SEO tags
 */
export function addSeoTagsProvider(
  router: UIRouter,
  params: ControllerParams,
  resolver: (
    transition: Transition,
  ) => Promise<SeoTagsPayload> | SeoTagsPayload,
) {
  return router.transitionService.onCreate({}, handleTransitionCreate);

  function handleTransitionCreate(transition: Transition) {
    const { controllerConfig } = params;
    const errorMonitor = monitor(params.flowAPI);

    transition.addResolvable(
      {
        token: 'SEO',
        deps: _.without(transition.getResolveTokens(), 'SEO'),
        async resolveFn() {
          try {
            const result = await resolver(transition);

            controllerConfig.wixCodeApi.seo.renderSEOTags(result);

            return result;
          } catch (error: any) {
            errorMonitor.captureException(error as Error, {
              transaction: 'SEO',
            });
            console.error(error);
          }
        },
      },
      transition.to(),
    );
  }
}

export function config404(
  router: UIRouter,
  params: ControllerParams,
  states: Record<string, string[]>,
) {
  const { seo } = params.controllerConfig.wixCodeApi;

  for (const name in states) {
    const deps = states[name];

    router.transitionService.onError(
      { to: name },
      async function (transition) {
        const injector = transition.injector();
        const error = transition.error();

        if (error.type !== RejectType.ERROR) {
          return;
        }

        try {
          await Promise.all(deps.map((token) => injector.getAsync(token)));
        } catch {
          seo.setSeoStatusCode(404);
        }
      },
      { invokeLimit: 1, priority: 999 },
    );
  }
}
